<template>
  <div class="view-header">
    <router-link :to="!isFromPcWeb?'/home':''">
      <img @dragstart.prevent class="view-header__img unInvertColor" src="/static/logo.png" alt=""/>
      <img @dragstart.prevent class="view-header__img" src="@/assets/images/layout/yiXinAI.png" alt=""/>
    </router-link>
    <div class="nav-menu" v-if="!isFromPcWeb">
      <div class="list-btn">
        <el-dropdown>
          <div class="el-dropdown-link">
            <img src="../../assets/images/whiteHome/sdStore.svg" alt=""/>AI<span>工具</span>
            <el-icon class="el-icon--right">
              <arrow-down/>
            </el-icon>
          </div>
          <template #dropdown>
            <div class="menu-navigation">
              <div v-for="(item,index) in state.AIToolsData" :key="index" class="navigation-item"
                   @click="goToOpenUrl(item)">
                <div>
                  {{ item.applyName }}
                </div>
              </div>
            </div>
          </template>
        </el-dropdown>
      </div>
      <template v-if="!isFromPcWeb">
        <div class="list-btn" @click="goToPrompt">
          <img src="@/assets/images/whiteHome/Baodian.svg" alt=""/>提示词<span>宝典</span>
        </div>
        <router-link class="list-btn" to="/home/artGalleryList" style="text-decoration: none;">
          <img src="@/assets/images/whiteHome/sqCommunity.svg" alt=""/><span>社区</span>
        </router-link>
        <div class="list-btn" @click="goToWorkshop()">
          <img src="@/assets/images/whiteHome/workshop.svg" alt=""/>AI<span>工坊</span>
        </div>
      </template>
    </div>
    <div style="flex: 1"></div>
    <!--未登录-->
    <div v-if="!state.userInfoGlobal.isLogin&&!isFromPcWeb" @click="loginClick" class="view-header__login">
      <div style="color: #9e9e9e; margin: 0 25px">登录/注册</div>
      <img @dragstart.prevent src="@/assets/images/layout/userPhoto.png" alt="img-header"/>
    </div>
    <!--已登录-->
    <div v-if="!state.userInfoGlobal.isSvip && state.userInfoGlobal.isLogin &&!isFromPcWeb" class="view-header__title1"
         @click="vipBuyClick">
      <span><img class="unInvertColor" @dragstart.prevent src="@/assets/images/gallery/icon-vip.svg"
                 alt="img-vip"/></span>
      加入超级会员，领取更多AI点数
    </div>
    <div v-if="state.userInfoGlobal.isLogin&&!isFromPcWeb" class="message-center mx-4 position-relative cur"
         @click="goToMessageCenter()">
      <img @dragstart.prevent src="@/assets/images/messageCenter/bell.svg" class="unInvertColor">
      <div v-if="state.unread > 0"
           class="message-num position-absolute d-flex justify-content-center align-items-center">
        {{ state.unread > 99 ? '99+' : state.unread }}
      </div>
    </div>
    <div v-if="state.userInfoGlobal.isLogin&&!isFromPcWeb" class="view-header__title px-3 me-3">
      <img class="unInvertColor" @dragstart.prevent src="@/assets/images/gallery/icon-star.svg" alt="img-star"/>
<!--      &ensp;剩余AI点数:&nbsp;-->
      <span class="unInvertColor" style="color: #ffa444">{{ prop.assetsObj || 0}}</span>
      &ensp;&ensp;|&ensp;&ensp;
      <span class="py-2 cur" @click="buyAiAsset()">购买点数</span>
    </div>
    <div v-if="state.userInfoGlobal.isLogin&&!isFromPcWeb" style="display: flex;">
      <div class="view-header__logout" ref="buttonRef" v-click-outside="onClickOutside">
        <img @dragstart.prevent :src="state.userInfoGlobal.headimgurl" alt="img-header"
             class="user-header unInvertColor"/>
        <!--        <div class="user-nickname unInvertColor">{{ state.userInfoGlobal.nickname }}</div>-->
      </div>
      <el-popover placement="bottom" width="50" trigger="click" ref="popoverRef" :virtual-ref="buttonRef"
                  virtual-triggering>
        <router-link style="text-decoration: none" :to="{name: 'personal'}">
          <el-button size="small" style="width: 100%; margin: 0; margin-top: 0.5rem" @click="selectClose" plain>个人中心
          </el-button>
        </router-link>
        <el-button size="small" style="width: 100%; margin: 0; margin-top: 0.5rem" plain @click="changeStyle()"> 切换主题
        </el-button>
        <el-button size="small" style="width: 100%; margin: 0; margin-top: 0.5rem" plain @click="openServeDialog()">
          亦心AI服务协议
        </el-button>
        <el-button size="small" style="width: 100%; margin: 0; margin-top: 0.5rem" plain @click="openUseBook()">
          亦心AI用户手册
        </el-button>
        <el-button size="small" style="width: 100%; margin: 0; margin-top: 0.5rem" plain @click="getAiAsset()">
          获取更多AI点数
        </el-button>
        <el-button size="small" style="width: 100%; margin: 0; margin-top: 0.5rem" plain @click="logout">退出登录
        </el-button>
      </el-popover>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed, onMounted, reactive, ref, inject, defineEmits, unref, watch } from 'vue'
import userInfoService from '@/utils/service/userinfoservice'
import { ClickOutside as vClickOutside } from 'element-plus'
import { getBrowerType } from '@/utils/system/systemUtils'
import { useStore } from 'vuex'

onMounted(() => {
  state.unread = store.state.userInfo.unread
})
const store = useStore()
const router = useRouter()
const userInfoGlobal = ref(inject('$userInfoGlobal'))
const emit = defineEmits(['loginClick'])
const unread = computed(() => { return store.state.userInfo.unread })
watch(unread, () => { state.unread = store.state.userInfo.unread })
const state = reactive({
  userInfoGlobal,
  showPopover: false,
  currentSelect: '',
  AIToolsData: [
    {
      applyName: 'AI工作站',
      linkUrl: window.SITE_CONFIG.aiBaseUrl + '/#/making'
    },
    {
      applyName: '动态漫',
      linkUrl: window.SITE_CONFIG.aiBaseUrl + '/#/aiCartoon'
    },
    {
      applyName: 'AI闪绘',
      linkUrl: window.SITE_CONFIG.officialWebsiteBaseUrl + '/#/AiFlashDrawing'
    },
    // {
    //   applyName: 'AI海报',
    //   linkUrl: window.SITE_CONFIG.saasUrl + '/#/toolbox'
    // },
    {
      applyName: 'AI商品图',
      linkUrl: window.SITE_CONFIG.aiBaseUrl + '/#/making?type=wares'
    },
    {
      applyName: 'AI海报',
      linkUrl: window.SITE_CONFIG.saasUrl + '/#/toolbox'
    },
    {
      applyName: 'AI模特',
      linkUrl: window.SITE_CONFIG.aiBaseUrl + '/#/makingForModel'
    }
  ],
  unread: 0 // 未读消息
})

// console.log('登录数据', state.userInfoGlobal)
const prop = defineProps({
  assetsObj: {
    type: String
  }
})
/**
 * 跳转到指定页面
 */
const goToOpenUrl = (item) => {
  if (!state.userInfoGlobal.isLogin) {
    window.toLogin()
    return
  }
  if (item.linkUrl) {
    window.open(item.linkUrl, '_blank')
  }
}
const isFromPcWeb = getBrowerType() === 'pcWeb'
const buttonRef = ref()
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef).popperRef?.delayHide?.()
}
const selectClose = () => {
  const p = popoverRef.value
  p && (p.hide())
}
// 用户手册
const openUseBook = () => {
  const url = 'https://help.photosir.com/aigc/index.html'
  // if(this.$root.from==='pc' || this.$root.from==='pcWeb'){
  //   //qt打开网页
  //   let bridge;
  //   new QWebChannel(qt.webChannelTransport,
  //       function (channel) {
  //         bridge = channel.objects.bridge
  //         bridge.popupUrlWindow(url)
  //       });
  // }else {
  window.open(url)
  // }
}
// 打开服务协议
const openServeDialog = () => {
  window.showServeDialog()
}
/** * 登录/注册按钮被点击 */
const loginClick = () => {
  emit('loginClick')
}
// 购买ai点数弹窗
const vipBuyClick = () => {
  window.buyAiTimes('svip')
}
// 智造工坊
const goToWorkshop = () => {
  router.push({
    name: 'workshopHome'
  })
}
// 智造工坊
const goToMessageCenter = () => {
  router.push('/home/message')
}
// 跳转提示词
const goToPrompt = () => {
  const routerUrl = router.resolve({
    name: 'prompt'
  })
  window.open(routerUrl.href)
}

// 购买点数
const buyAiAsset = () => {
  window.buyAiTimes()
}

// 更多ai点数
const getAiAsset = () => {
  selectClose()
  router.push('/home/task')
  // window.buyAiTimes()
}
// 退出登录
const logout = () => {
  console.log('router', router.currentRoute.value.name)
  userInfoService.logout(state.userInfoGlobal).then(() => {
    state.userInfoGlobal = userInfoService.getUserData(state.userInfoGlobal)
    if (router.currentRoute.value.name === 'personal' || router.currentRoute.value.name === 'task') {
      router.push('/home/galleryList')
    }
  })
}
// 切换主题
const changeStyle = () => {
  window.toSetStyle()
}
</script>

<style lang="scss" scoped>
.view-header {
  position: fixed;
  z-index: 9;
  top: 0;
  background-color: #fff;
  // width: calc(100vw - 37px - 37px);
  width: 100%;
  margin: 0 auto;
  height: 70px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  .view-header__img {
    height: 1.89rem;
    margin-right: 6px;
  }
  .nav-menu {
    display: flex;
    margin-left: 80px;
    .list-btn{
      margin: 0 10px;
      display: flex;
      align-items: center;
      color: #000;
      cursor: pointer;
      font-size: 16px;
      img{
        width: 35px;
      }
      span {
        color: #3578f6
      }
    }
  }
  .view-header__title1 {
    min-width: max-content;
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    color: #4a2f2f;
    background: linear-gradient(98deg, #caa07d 8%, #fff3e1 96%);
    margin-right: 20px;
    padding: 0 28px 0 20px;
    border-radius: 19px;
    display: flex;
    align-items: center;
    cursor: pointer; /*小手*/
    span{
      img{
        height: 18px;
        margin-right: 6px
      }
    }
  }
  .view-header__title {
    height: 50px;
    border-radius: 50px;
    border: 1px gray solid;
    font-size: 18px;
    color: #9e9e9e;
    display: flex;
    align-items: center;
    img{
      width: 35px;
    }
  }
  .view-header__login {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    div{
      font-size: 16px;
    }
    img{
      width: 35px;
    }
  }
  .message-center {
    img{
      width: 35px;
    }
    .message-num {
      width: 24px;
      height: 24px;
      padding: 4px;
      top: -8px;
      right: -8px;
      border-radius: 50%;
      background-color: red;
      color: #ffffff;
      font-size: 12px;
      text-align: center;
    }
  }
  .view-header__logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    .user-nickname {
      font-size: 16px;
      color: #9e9e9e;
      margin: 0 20px;
    }
    .user-header {
      width: 40px;
      max-width: 40px;
      height: 40px;
      max-height: 40px;
      border-radius: 50%;
    }
  }
}
$images: 'dgzz', 'ddtm', 'dsh', 'dmt', 'dspt', 'dhb';
$hover-images: 'ugzz', 'udtm', 'ush', 'umt', 'uspt', 'uhb';
.menu-navigation{
  width: 300px !important;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 0 30px 30px;
  @for $i from 1 through length($images) {
  .navigation-item:nth-child(#{$i}) {
    background-image: url('../../assets/images/home/#{nth($images, $i)}.svg');
  }
  .navigation-item:nth-child(#{$i}):hover {
    background-image: url('../../assets/images/home/#{nth($hover-images, $i)}.svg');
  }
}
  .navigation-item{
    width: 50%;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 20px 20px;
    border-right: #D8D8D8 1px solid;
    border-bottom: #D8D8D8 1px solid;
    div{
      margin-left: 50px;
    }
  }
  .navigation-item:hover{
    background-color: #3578F6;
    color: #fff;
  }
}
.el-dropdown-link:focus {
  outline: none;
}
</style>
